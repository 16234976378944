import GatsbyTemplate from './gatsby-template';

import HowTo from '../../frontend/components/howTo';
import { ResourceBundles } from '../infrastructure/language-service';

export type PageProps = {
  resourceBundles: ResourceBundles;
};

export default GatsbyTemplate<PageProps>(HowTo);
